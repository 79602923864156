@import "./fonts.css";

body,
#root {
  min-height: 100vh;
  margin: 0;
}

.login-page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

[dir="rtl"] .MuiSelect-iconFilled {
  right: auto;
  left: 7px;
}

[dir="rtl"] .MuiSelect-filled.MuiSelect-filled {
  padding-right: 12px;
}

[dir="ltr"] .MuiSelect-iconFilled {
  left: auto;
  right: 7px;
}

[dir="ltr"] .MuiSelect-filled.MuiSelect-filled {
  padding-left: 12px;
}

/***************************/
/***************************/
/***************************/
/******* DATA GRIDS ********/
/***************************/
/***************************/
/***************************/

.data-grid-container .data-grid tr .cell.index-cell {
  min-width: 50px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.05);
  border-color: rgba(0, 0, 0, 0.45);
}

.data-grid-container .data-grid tr .cell {
  min-width: 200px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.5);
  border-color: rgba(0, 0, 0, 0.45);
  text-align: center;
  vertical-align: middle;
}
.data-grid-container .data-grid tr .cell.read-only {
  background-color: rgba(0, 0, 0, 0.05);
  color: inherit;
}

.data-grid-container .data-grid tr .cell > input {
  height: 100%;
  width: 100%;
  text-align: center;
}

/***************************/
/***************************/
/********* IFRAMES *********/
/***************************/
/***************************/

.MuiDialog-paperScrollPaper {
  height: 100%;
}

.frontdesk-iframe {
  border: none;
}

.MuiDialogContent-root.MuiDialogContent-dividers {
  padding: 0;
}

.iframe-embeded #root > header,
.iframe-embeded #root > nav,
.iframe-embeded #root > main > div:first-child,
.iframe-embeded #root main .resource-toolbar {
  display: none;
}

.iframe-embeded #root > main {
  margin: 0;
  width: 100%;
}

/***************************/
/***************************/
/******** PRINTING *********/
/***************************/
/***************************/

.print-only {
  opacity: 0;
  width: 0;
  height: 0;
}

@media only print {
  .print-only {
    opacity: 1;
    width: auto;
    height: auto;
  }

  .MuiPaper-elevation4 {
    box-shadow: none !important;
  }
}
